import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { formatDatePretty } from "src/utils/dateUtils";

import styles from "./ToolSnippet.module.scss";

const PostListingDetailed = ({ title, path, description, date }) => {
	let prettyDate;
	if (date) {
		prettyDate = formatDatePretty(date);
	}

	console.log(styles);
	console.log(`${styles.toolBlock}`);

	return (
		<div key={title} className={`postBlock ${styles.toolBox}  p-md`}>
			<Link to={path} className="hoverLink">
				<h3 className="m-b-sm">{title}</h3>
			</Link>
			<p className="noPMargin">{description}</p>
			<div className="m-t-sm">
				<span className={`${styles.greyTag}`}>{prettyDate}</span>
			</div>
		</div>
	);
};

PostListingDetailed.propTypes = {
	postEdges: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default PostListingDetailed;
