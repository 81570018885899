import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import PostListingDetailed from "src/components/generic/PostListingDetailed/PostListingDetailed";
import ToolSnippet from "src/components/generic/ToolSnippet/ToolSnippet";
import config from "data/SiteConfig";

import {
	RESOURCE_LIBRARY,
	TOOL_FINDING_CANNIBALISATION,
	TOOL_DOWNLOAD_SEARCH_CONSOLE
} from "src/routesConstants";

const scDataDownloaderDate = new Date(2020, 5, 4);

const tools = [
	{
		title: "Search Console Data Downloader",
		description:
			"This is a tool for downloading day by day data from search console.",
		path: TOOL_DOWNLOAD_SEARCH_CONSOLE,
		date: scDataDownloaderDate
	}
];

const ResourcesPage = ({ data }) => {
	const formattedTools = tools.map(tool => {
		return (
			<ToolSnippet
				key={tool.path}
				title={tool.title}
				description={tool.description}
				path={tool.path}
				date={tool.date}
			/>
		);
	});

	return (
		<Layout>
			<main>
				<Helmet>
					<title>Resources - {config.siteTitle}</title>
				</Helmet>
				<div className={`m-b-xl m-t-xl container outerContainer`}>
					<div className="standoutBlock">
						<h1 className="is-size-5">Resource Library</h1>

						<p>
							Would you like access to our collection of free
							Looker Studio templates & extra resources? Sign-up
							and join the group!
						</p>
						<Link
							to={RESOURCE_LIBRARY}
							className="button buttonSecondary m-r-md"
						>
							Join now
						</Link>
					</div>
				</div>
				<div className={`m-b-xl m-t-xl container outerContainer`}>
					<h1 className="is-size-3">Resources</h1>
					<PostListingDetailed
						postEdges={data.allMdx.edges}
						twoColumns={true}
					/>
					<h1 className="is-size-3 m-t-xl m-b-md">Tools</h1>
					<div className={`twoColumns`}>{formattedTools}</div>
				</div>
			</main>
		</Layout>
	);
};

ResourcesPage.propTypes = {
	data: PropTypes.shape()
};

export default ResourcesPage;

/* eslint no-undef: "off" */
/**
 * Here we get to select the variables out of page context.
 */
export const pageQuery = graphql`
	query ResourcesPage {
		allMdx(
			limit: 1000
			sort: { fields: [fields___date], order: DESC }
			filter: {
				frontmatter: {
					draft: { eq: false }
					resourceLibrary: { ne: true }
				}
			}
		) {
			totalCount
			edges {
				node {
					fields {
						slug
						date(formatString: "MMMM DD, YYYY")
					}
					frontmatter {
						h1title
						date
						description
					}
				}
			}
		}
	}
`;
